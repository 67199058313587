<template>
  <div class="addPlan">
    <div class="addPlan-wrap">
      <div class="addPlan-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn" src="@/assets/images/pop01.png" alt="" />
      </div>
      <div class="addPlan-content">
        <div class="ff-c">
          <div class="fi-c">
            <div class="it">计划进度名称</div>
            <el-input v-model="inputs1" placeholder="请输入名称"></el-input>
          </div>
          <div class="fi-c">
            <div class="it">编制人</div>
            <el-input v-model="inputs2" placeholder="请输入名称"></el-input>
          </div>
          <div class="fi-c">
            <div class="it">编制时间</div>
            <el-date-picker
              v-model="datevalue"
              type="date"
              placeholder="请选择时间"
              :disabled-date="disabledDate"
          
            >
            </el-date-picker>
          </div>
        </div>
        <div class="gt-c">
          <div class="gtc-t">
            <div class="gq-btn" @click="typeChange(1)">日视图</div>
            <div class="gq-btn" @click="typeChange(2)">月视图</div>
            <div class="gq-btn" @click="typeChange(3)">年视图</div>
            <div class="gq-btn" @click="typeChange(4)">默认视图</div>
            <div class="gq-btn" @click="showWeek(1)">显示周末</div>
            <div class="gq-btn" @click="showWeek(2)">隐藏周末</div>
            <!-- <div class="gq-btn" @click = "fullScreen">全屏预览</div> -->
            <div class="gq-btn" @click="ImportTask">导入</div>
            <div class="gq-btn" @click = "down">导出</div>
          </div>
          <div class="gtc-b" id="gantt_here"></div>
        </div>
        <div class="btns">
          <div class="ac-btn" @click="closefn">取消</div>
          <div class="ac-btn" :class = "{disable:(!reqdata.length && addtitle != '编辑计划进度') || !inputs1 || !inputs2 || !datevalue || !datevalues || !datevaluee}" @click = "allsub">确认</div>
        </div>
      </div>
      <div class="up-box-w" v-if="boxshow">
     
        <div class="up-box">
             <img @click="giveup" class="updel" src="@/assets/images/pop01.png" alt="" />
          <div class="selectfile">
            <input type="file" @change="referenceUpload" ref="upinput" />
            <div class="f-input-text">
              {{ projectFile.name }}
            </div>
          </div>
          <div class="tips" v-if="Parsing">{{importstatus}}</div>
          <div class="ub-btn-c">
            <!-- <div class="ub-btn" @click="giveup">取消</div>
            <div
              class="ub-btn"
              :class="{ disable: !datalist.length }"
              @click="sure"
            >
              确认
            </div> -->
          </div>
        </div>
      </div>
      <div class="task-box" v-if = "taskboxshow">
        <div class="tbox-c">
          <div class="tbc-t">
            <div class="close-btn"  @click="cancel">&times;</div>
          </div>
          <div class="tbc-c">
            <div class="tbc-line">
              <div class="tl-title">任务名称： </div>
              <div class="tl-content">
                <el-input v-model="onetaskdata.name" placeholder="请输入任务名称"></el-input>
              </div>
            </div>
            <div class="tbc-line">
              <div class="tl-title">计划开始时间： </div>
              <div class="tl-content">
                <el-date-picker
                  v-model="onetaskdata.planStartTime"
                  type="date"
                  placeholder="请选择时间"
            
                  format="YYYY-MM-DD"
                   value-format="YYYY-MM-DD"
                  :disabled-date="disabledDates"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="tbc-line">
              <div class="tl-title">计划结束时间： </div>
              <div class="tl-content">
                <el-date-picker
                  v-model="onetaskdata.planEndTime"
                  type="date"
                  placeholder="请选择时间"
                  :disabled-date="disabledDatee"
              
                    format="YYYY-MM-DD"
                   value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="tbc-line">
              <div class="tl-title">是否为里程碑： </div>
              <div class="tl-content">
                <el-select v-model="onetaskdata.isMilestone" class="m-2" placeholder="Select" size="large">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="tbc-b">
            <div class="tbc-btn" @click="cancel">取消</div>
            <div class="tbc-btn" v-if = "delshow" @click="deltask">删除</div>
            <div class="tbc-btn" :class = "{disable:!onetaskdata.planStartTime || !onetaskdata.planEndTime ||  onetaskdata.isMilestone == -1}" @click="editTask">确认</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { UpLoad,AddPlanProject,GetTreeData,AddProjectTask,DeleteData } from "@/js/progressApi";
export default {
  props: {
    addfalse: Boolean,
    bonus: Number,
    addtitle: String,
    editdata: Object,
  },
  setup(props, { emit }) {
    const cdata = getStorage("bscdata");
    const state = reactive({
      options:[
        {
          value: -1,
          label: '无',
        },
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        }
      ],
      onetaskdata:{// 一个任务
        name:'', // 任务名称
        planStartTime:'', // 计划开始时间
        planEndTime:'', // 计划结束时间
        isMilestone:-1, // 是否 里程碑
        id:'',
        temporaryid:'',
        number:'',
        paretn_ProjectTask: {
            id: "00000000-0000-0000-0000-000000000000"
        },
        planProject: {
            id: ""
        }
      }, 
      taskboxshow:false, // 编辑一个任务的弹框
      ptaskid:"", // 数据id
      inputs1: "", //计划进度名称
      inputs2: "",// 编制人
      datevalue: "", // 编制时间
      datevalues: "",// 总任务开始时间
      datevaluee: "",// 总任务结束时间
      boxshow: false,// 导入小弹框
      upinput: null,// project 文件
      projectFile: { name: "上传文件" },
      datalist: [],// 用来展示 gantt 图的数据
      Parsing: false,// 是否导入中
      viewType:1,
      showweek:1,
      importstatus:'上传中请勿操作……',
      reqdata:[],// 用来导入请求接口的数据
      resposedata:'', // 后台返回的任务数据
      addtitle: "",
      delshow:false,
    });
    const closefn = () => {
      emit("addfalsefn");
    };
    const recursion = (data) => {
     
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i]&&data[i].childlist&&data[i].childlist.length > 0) {
          let childlist = recursion(data[i].childlist);
          for (let j = 0; j < childlist.length; j++) {
            if (childlist[j].parent != 0) {
              arr.push(childlist[j]);
            } else {
              arr.push({
                id: childlist[j].id,
                number: childlist[j].number,
                name: childlist[j].name,
                start_date: childlist[j].start_date,
                end_date: childlist[j].end_date,
                day: childlist[j].day,
                isMilestone: childlist[j].isMilestone,
                parent: data[i].ID,
              });
            }
          }
          // console.log(data[i],data[i].isMilestone,data[i].isMilestone == true ? "是" : "否")
          arr.push({
            id: data[i].ID,
            number: data[i].Number,
            name: data[i].Name,
            start_date: data[i].PlanStartTime,
            end_date: data[i].PlanEndTime,
            day: data[i].day,
            isMilestone: data[i].IsMilestone == true ? "是" : "否",
            parent: 0,
          });
        } else {
          arr.push({
            id: data[i].ID,
            number: data[i].Number,
            name: data[i].Name,
            start_date: data[i].PlanStartTime,
            end_date: data[i].PlanEndTime,
            day: data[i].day,
            isMilestone: data[i].IsMilestone == true ? "是" : "否",
            parent: 0,
          });
        }
      }
      return arr;
    };
    const resetonetaskdata = ()=>{
      state.onetaskdata = {// 一个任务
        name:'', // 任务名称
        planStartTime:'', // 计划开始时间
        planEndTime:'', // 计划结束时间
        isMilestone:-1, // 是否 里程碑
        id:'',
        temporaryid:'',
        number:'',
        paretn_ProjectTask: {
            // id: "00000000-0000-0000-0000-000000000000"
            id:state.onetaskdata&&state.onetaskdata.paretn_ProjectTask&&state.onetaskdata.paretn_ProjectTask.id?state.onetaskdata.paretn_ProjectTask.id:"00000000-0000-0000-0000-000000000000"
        },
        planProject: {
            id: state.ptaskid
        }
      }
    }
    const setganttevent = ()=>{
        gantt.showLightbox = function (id) {
            var task = gantt.getTask(id);
            // methods.timefn(task.end_date)
            console.log(task)
            //新建根目录
            state.onetaskdata.planProject.id =state.ptaskid;
            state.onetaskdata.temporaryid = task.id;
            if (task.parent == 0 && task.text == "新任務") {
                console.log("新建根目录")
            }
            //新建下级目录
            else if (task.parent != 0 && task.text == "新任務") {
                state.onetaskdata.paretn_ProjectTask.id = task.parent == 0 ? "00000000-0000-0000-0000-000000000000" : task.parent;
                console.log("新建下级目录")
            }
            //修改目录
            else if (task.id != null && task.text == null) {
              state.onetaskdata = {
                  id: task.id,
                  temporaryid:task.id,
                  name: task.name,
                  number:task.number,
                  planStartTime: task.start_date,
                  planEndTime: task.end_date,
                  isMilestone: task.isMilestone == "否"?0:task.isMilestone == "是"?1:-1,
                  paretn_ProjectTask: {
                      id: task.parent == 0 ? "00000000-0000-0000-0000-000000000000" : task.parent
                  },
                  planProject: {
                      id: state.ptaskid
                  }
              }
              state.delshow = true;
              console.log("修改目录")
            }
            console.log(state.onetaskdata)
            state.taskboxshow = true;

            // if (app.project.id == "") {
            //     app.cancel();
            //     show_tip_modal("项目不能为空,请先添加项目。")
            //     return;
            // }
            // $("#PlanStartTime").val("");
            // $("#PlanEndTime").val("");
            // //新建根目录
            // if (task.parent == 0 && task.text == "新任務") {
            //     state.onetaskdata = {
            //         id: "",
            //         name: "",
            //         planStartTime: "",
            //         planEndTime: "",
            //         isMilestone: "",
            //         paretn_ProjectTask: {
            //             id: ""
            //         },
            //         planProject: {
            //             id: app.project.id
            //         }
            //     }
            // }
            // //新建下级目录
            // else if (task.parent != 0 && task.text == "新任務") {
            //     state.onetaskdata = {
            //         id: "",
            //         name: "",
            //         planStartTime: "",
            //         planEndTime: "",
            //         isMilestone: "",
            //         paretn_ProjectTask: {
            //             id: task.parent == 0 ? "" : task.parent
            //         },
            //         planProject: {
            //             id: app.project.id
            //         }
            //     }
            // }
            // //修改目录
            // else if (task.id != null && task.text == null) {

            //     state.onetaskdata = {
            //         id: task.id,
            //         name: task.name,
            //         planStartTime: task.start_date,
            //         planEndTime: task.end_date,
            //         isMilestone: task.isMilestone == "是" ? true : false,
            //         paretn_ProjectTask: {
            //             id: task.parent == 0 ? "" : task.parent
            //         },
            //         planProject: {
            //             id: app.project.id
            //         }
            //     }
            //     $("#PlanStartTime").val(app.FormDate(state.onetaskdata.planStartTime));
            //     $("#PlanEndTime").val(app.FormDate(state.onetaskdata.planEndTime));
            //     $("#Delete").show();
            // }
            // app.add_model();
        }  
  
    }
    const initgantt = () => {
      //start 自定义第一时间轴 （为合并行效果）
      gantt.config.scale_unit = "year"; //按年显示
      gantt.config.open_tree_initially = true;
      gantt.config.step = 1; //步进，默认为1
      gantt.config.date_scale = "%Y年"; //日期格式 先数字后文字
      //剩下的时间轴
      gantt.config.subscales = [
        { unit: "month", step: 1, date: "%M" },
        { unit: "day", step: 1, date: "%d-周%D" },
      ];
      gantt.config.scale_height = 60; //设置时间尺度和Grid树的高度
      //end

      //Start高亮显示周末
      gantt.templates.scale_cell_class = function (date) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          return "weekend";
        }
      };
      gantt.templates.timeline_cell_class = function (task, date) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          return "weekend";
        } 
      };
      //End

      //Start 显示/隐藏周末
      if (state.showweek == 1) {
        // 0 refers to Sunday, 6 - to Saturday
        gantt.ignore_time = function (date) {
          if (date.getDay() == 0 || date.getDay() == 6) return false;
        };
      } else {
        // 0 refers to Sunday, 6 - to Saturday
        gantt.ignore_time = function (date) {
          if (date.getDay() == 0 || date.getDay() == 6) return true;
        };
      }
      //End

      //Start 设置时间轴显示视图
      if (state.viewType == 1) {
        gantt.config.scale_unit = "day"; //按天显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%d-周%D"; //日期格式 先数字后文字
        gantt.config.subscales = []; //清空其他行时间轴
      } else if (state.viewType == 2) {
        gantt.config.scale_unit = "month"; //按月显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%M"; //日期格式 先数字后文字
        gantt.config.subscales = []; //清空其他行时间轴
      } else if (state.viewType == 3) {
        gantt.config.scale_unit = "year"; //按年显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%Y年"; //日期格式 先数字后文字
        gantt.config.subscales = []; //清空其他行时间轴
      } else if (state.viewType == 4) {
        //start 自定义第一时间轴 （为合并行效果）
        gantt.config.scale_unit = "year"; //按年显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%Y年"; //日期格式 先数字后文字
        //剩下的时间轴
        gantt.config.subscales = [
          { unit: "month", step: 1, date: "%M" },
          { unit: "day", step: 1, date: "%d-周%D" },
        ];
        //end
      }
      //End

      //Start 设置时间轴的高
      gantt.config.task_height = 20;
      gantt.config.row_height = 40;
      //End

      gantt.config.keep_grid_width = true;

      gantt.config.autofit = true;
      gantt.config.autoscroll = true; //把任务或者连线拖拽到浏览器屏幕外时，自动触发滚动效果，相当于拖动滚动条
      gantt.config.layout = {
        css: "gantt_container",
        cols: [
          {
            width: 600,
            min_width: 400,
            rows: [
              {
                view: "grid",
                scrollX: "gridScroll",
                scrollable: true,
                scrollY: "scrollVer",
              },

              // horizontal scrollbar for the grid
              { view: "scrollbar", id: "gridScroll", group: "horizontal" },
            ],
          },
          { resizer: true, width: 1 },
          {
            rows: [
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              // horizontal scrollbar for the timeline
              { view: "scrollbar", id: "scrollHor", group: "horizontal" },
            ],
          },
          { view: "scrollbar", id: "scrollVer" },
        ],
      };
      gantt.config.columns = [
        {
          name: "wbs",
          label: "大纲",
          align: "center",
          resize: true,
          tree: true,
          width: 150,
          template: gantt.getWBSCode,
        },
        {
          name: "number",
          label: "任务编号",
          align: "center",
          resize: true,
          width: 110,
        },
        {
          name: "name",
          label: "任务名称",
          align: "center",
          resize: true,
          width: 200,
        },
        {
          name: "start_date",
          label: "计划开始时间",
          align: "center",
          resize: true,
          width: 180,
        },
        {
          name: "end_date",
          label: "计划结束时间",
          align: "center",
          resize: true,
          width: 180,
        },
        {
          name: "day",
          label: "工期",
          align: "center",
          resize: true,
          width: 100,
        },
        {
          name: "isMilestone",
          label: "是否为里程碑",
          align: "center",
          resize: true,
          width: 180,
        },
        { name: "add", label: "" },
      ];
      gantt.config.date_format = "%Y-%n-%d %H:%i:%s";
      gantt.init("gantt_here");
      gantt.clearAll();

      // var dd = app.recursion(app.model);
      if(state.resposedata){ // 如果是解析后台返回的数据
        // console.log(state.resposedata,'state.resposedata')
        gantt.parse({
          data: state.resposedata,
        });
      }else{//
        console.log(state.datalist,'state.datalist',547)
        gantt.parse({
          data: state.datalist,
        });
        state.datevalues = dayjs(gantt._getProjectStart()).format('YYYY-MM-DD');
        state.datevaluee = dayjs(gantt._getProjectEnd()).format('YYYY-MM-DD');
        
      }
      //常用功能性的设置
      //提示文本时间格式控制
      console.log(gantt.templates,55888,gantt.date.date_to_str)
      gantt.templates.tooltip_date_format = function (date) {
        var formatFunc = gantt.date.date_to_str("%Y-%m-%d %H:%i:%s");
        // console.log(formatFunc(date),date,56000)
        return formatFunc(date);
      };
      
      gantt.open(state.onetaskdata&&state.onetaskdata.paretn_ProjectTask&&state.onetaskdata.paretn_ProjectTask.id?state.onetaskdata.paretn_ProjectTask.id:'')    
      //指定工具提示的文本
      //gantt.templates.tooltip_text = function (start, end, task) {
      //    return "<b>任务编号:</b> " + task.number + "<br/>" +
      //        "<b>任务名称:</b > " + task.name + "<br/>" +
      //        "<b>开始时间:</b>" + gantt.templates.tooltip_date_format(task.start_date) + "<br/>" +
      //        "<b>结束时间:</b>" + gantt.templates.tooltip_date_format(task.end_date) + "<br/>" +
      //        "<b>工期:</b>" + task.type + "<br/>";
      //};

      //在时间轴显示任务名称
      gantt.templates.task_text = function (start, end, task) {
        // console.log(start, end, task,57555)
        return task.number;
      };

      // gantt.templates.task_text = function (start, end, task) {
      //   console.log(task,task.name,570)
      //   return task.name;
      // };

      //在任务栏的完成部分中指定文本
      //gantt.templates.progress_text = function (start, end, task) {
      //    return "";
      //};
    };
    const getGtreedata = ()=>{
      GetTreeData({planprojectID:state.ptaskid}).then((res02) => {
        // 获取树结构
        let { Code, Data, Message } = res02.data;
        if (Code == 1 && Data) {
          state.resposedata = recursion(Data);
          initgantt();
          // ElMessage({
          //   showClose: true,
          //   message: Message,
          //   type: "success",
          // });
        } else {
          // ElMessage({
          //   showClose: true,
          //   message: Message,
          //   type: "error",
          // });
        }
      });
    }
    const methods = {
      timefn:(datas)=>{
        datas=dayjs(datas).format('YYYY-MM-DD')
      },
      startfn:()=>{
        state.onetaskdata.planStartTime=dayjs(state.onetaskdata.planStartTime).format('YYYY-MM-DD')
      },
       endfn:()=>{
        state.onetaskdata.planEndTime=dayjs(state.onetaskdata.planEndTime).format('YYYY-MM-DD')
      },
      datefn:()=>{
        state.datevalue=dayjs(state.datevalue).format('YYYY-MM-DD')
      },
    
      deltask:()=>{
        let onetaskdata = state.onetaskdata;
        console.log(onetaskdata);
        if(onetaskdata.planProject.id && onetaskdata.id){
          const datas = {
            "id" :onetaskdata.id,
            "actualStartTime": null,
            "actualEndTime": null,
            "planProject": onetaskdata.planProject.id,
            "isMilestone": onetaskdata.isMilestone == 0?false:true,
            "user": cdata.uid,
            "paretn_ProjectTask": onetaskdata.paretn_ProjectTask.id
          }
          if(onetaskdata.name){
            datas.name = onetaskdata.name;
          }
          if(onetaskdata.planStartTime){
            datas.planStartTime = onetaskdata.planStartTime;
          }
          if(onetaskdata.planEndTime){
            datas.planEndTime = onetaskdata.planEndTime;
          
          }
          DeleteData([datas]).then((res) => {
            let { Code,Message } = res.data;
            if (Code == 1) {
              getGtreedata();
              resetonetaskdata();
              state.taskboxshow = false;
              state.delshow = false;
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }

      },
      editTask:()=>{
        let onetaskdata = state.onetaskdata;
        console.log(onetaskdata);
        if(onetaskdata.planStartTime && onetaskdata.planEndTime && onetaskdata.isMilestone != -1){
          
          methods.startfn()
          methods.endfn()
          methods.datefn()
          const datas = {
            "number": onetaskdata.number,
            "name": onetaskdata.name,
            "planStartTime": onetaskdata.planStartTime,
            "planEndTime": onetaskdata.planEndTime,
            "actualStartTime": null,
            "actualEndTime": null,
            "planProject": onetaskdata.planProject.id,
            "isMilestone": onetaskdata.isMilestone == 0?false:true,
            "user": cdata.uid,
            "paretn_ProjectTask": onetaskdata.paretn_ProjectTask.id
          }
          if(onetaskdata.id){
            datas.id = onetaskdata.id;
          }
          console.log(datas);
          AddProjectTask(datas).then((res) => {
            let { Code,Message } = res.data;
            if (Code == 1) {
              getGtreedata();
              resetonetaskdata();
              state.taskboxshow = false;
              state.delshow = false;
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }

      },
      cancel:()=> { // 修改任务弹框取消
        console.log(state.onetaskdata)
          let task = gantt.getTask(state.onetaskdata.temporaryid);
          if (task.$new){
            gantt.deleteTask(task.temporaryid);
          }
          resetonetaskdata();
          state.taskboxshow = false;
          state.delshow = false;
      },
      allsub:()=>{// 第一次提交 新增编辑项目计划
        console.log(state.inputs1,state.inputs2,state.datevalue,state.reqdata,state.datevalues,state.datevaluee)
        if(state.inputs1 && state.inputs2 && state.datevalue && (state.addtitle == "编辑计划进度" || state.reqdata.length) && state.datevalues && state.datevaluee){
          methods.datefn()
          methods.timefn(state.datevalues)
          methods.timefn(state.datevaluee)
          let datas = {
            "p_Name": state.inputs1,
            "createTime":state.datevalue,
            "p_StartTime": state.datevalues,
            "p_EndTime":state.datevaluee,
            "p_People": state.inputs2,
            "p_Start": 0, // 是否启用 [0,1] [未启用,启用]
            "user": cdata.uid,
            "projectID":  cdata.pid
          }
          if(state.ptaskid){
            datas.id = state.ptaskid;
          }
          console.log(datas)
          AddPlanProject(datas).then((res) => {
            console.log(res);
            // 导入实际进度
            let { Code, Data, Message } = res.data;
            if (Code == 1 && Data) {
              state.ptaskid = Data;
              methods.Import();
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            
              

            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }
      },
      sure:()=>{// 确认导入文件
        if (state.datalist.length) {
          let reqdata = [];
          state.datalist.forEach((v) => {
            v.name=v.text
            reqdata.push({
              id: v.id,
              duration: parseInt(v.duration),
              parent: v.parent,
              starttime: v.start_date,
              name: v.text,
            });
          });
          state.reqdata = reqdata;
          console.log(state.reqdata,111,state.datalist,764)
            initgantt();
          state.boxshow = false;
           
        }
      },
      down:()=> {
          if(state.resposedata){
            gantt.exportToExcel({
                server: "https://export.dhtmlx.com/gantt/project",
                name: "计划任务.xlsx",
                data: state.resposedata
            })

          }
      },
      fullScreen:() => {
          if (!gantt.getState().fullscreen) {
            gantt.expand();
          }
          else {
            gantt.collapse();
          }
      },
      showWeek:(type) => {
        state.viewType = type;
        initgantt();
      },
      typeChange:(type) => {
        state.viewType = type;
        initgantt();
      },
      giveup: () => {
        state.reqdata = [];
        state.datalist=[];
        gantt.clearAll();
        state.boxshow = false;
      },
   
      // 导入project 数据
      
      Import: () => {//?
        console.log(state.reqdata)
        if (state.reqdata.length) {
          UpLoad({
            // entity: JSON.stringify(state.reqdata).replace(),
            entity: state.reqdata,
            planprojectID: state.ptaskid,
            User:cdata.uid
          }).then((res) => {
            console.log(res);
            // 导入实际进度
            let { Code,Message } = res.data;
            if (Code == 1) {
              // getGtreedata();
                emit("addfalsefn");
              emit('GetPlanProjectList');
              // ElMessage({ //导入project 数据不需要导入成功提示
              //   showClose: true,
              //   message: Message,
              //   type: "success",
              // });
            } else {
              // ElMessage({
              //   showClose: true,
              //   message: Message,
              //   type: "error",
              // });
            }
          });
        }else{
            emit("addfalsefn");
            emit('GetPlanProjectList');
        }
      },
      referenceUpload: (e) => {
        state.projectFile = state.upinput.files[0];
        console.log(state.projectFile,859)
        let formData = new FormData();
        formData.append("file", state.projectFile);
        state.Parsing = true;
        state.importstatus = "上传中请勿操作……";
        gantt.importFromMSProject({
          server: "https://export.dhtmlx.com/gantt/project",
          data: formData,
          callback: function (project) {
            state.datalist = project.data.data;
             console.log(state.datalist[0].$raw.Start.substring(0,10),11,state.datalist,870)
            state.datalist.forEach((item,index)=>{
             
              item.start_date=(item.$raw.Start).substring(0,10)
              item.end_date=(item.$raw.Finish).substring(0,10)
            })
            
            state.importstatus = "导入成功";
            state.resposedata = "";
              console.log(state.datalist,111)
            
        
             methods.sure()
         
            
             ElMessage({
                showClose: true,
                message: "导入成功",
                type: "success",
              });
           
          },
        });
      },
      ImportTask: () => {
        state.boxshow = true;
      },
      disabledDate: (time) => {// 编制时间日期校验
        return time.getTime() > Date.now();
      },
      disabledDates: (time) => {// 任务计划开始时间 校验
        return state.onetaskdata.planEndTime && time.getTime() > new Date(state.onetaskdata.planEndTime);
      },
      disabledDatee: (time) => {// 任务计划结束时间 校验
        return state.onetaskdata.planStartTime && time.getTime() < new Date(state.onetaskdata.planStartTime);
      }
    };
    onMounted(() => {
      state.addtitle = props.addtitle;
      if (state.addtitle == "编辑计划进度") {
        console.log(props.editdata);
        state.inputs1 = props.editdata.P_Name;
        state.inputs2 = props.editdata.P_People;
        state.ptaskid = props.editdata.ID;
        state.datevalue = dayjs(props.editdata.CreateTime).format('YYYY-MM-DD');
        state.datevalues = dayjs(props.editdata.P_StartTime).format('YYYY-MM-DD');
        state.datevaluee = dayjs(props.editdata.P_EndTime).format('YYYY-MM-DD');
        console.log(state.datevalue,state.datevalues,state.datevaluee)
        getGtreedata();
       
      }
      setganttevent();
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addPlan {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addPlan-wrap {
    width: 80%;
    min-height: 70%;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addPlan-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    .up-box-w {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      .updel{
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
      }
      .up-box {
        width: 500px;
        height: 300px;
        background: #fff;
        border-radius: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .selectfile {
          min-width: 100px;
          height: 30px;
          position: absolute;
          font-size: 14px;
          color: #fff;
          line-height: 30px;
          text-align: center;
          padding: 0 10px;
          left: 50%;
          top: 30%;
          transform: translate(-50%, 0);
          background: rgba(0, 180, 255, 0.1);
          border-radius: 4px;
          border: 1px solid #0083ff;
          font-size: 12px;
          color: #0083ff;
          input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
        .ub-btn-c {
          position: absolute;
          bottom: 20px;
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          .ub-btn {
            border: 1px solid #ddd;
            border-radius: 4px;
            height: 30px;
            line-height: 30px;
            padding: 0 20px;
            cursor: pointer;
            font-size: 14px;
            color: #666;
            &.disable {
              opacity: 0.5;
              cursor: no-drop;
            }
          }
        }
        .tips {
          position: absolute;
          top: 55%;
          width: 100%;
          text-align: center;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }

  .addPlan-content {
    width: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    bottom: 0;
    .ff-c {
      margin: 20px 0 0;
      display: flex;
      align-items: center;
      .fi-c {
        display: flex;
        align-items: center;
        margin: 0 50px 0 0;
        .it {
          font-size: 14px;
          color: #555555;
          word-break: keep-all;
          margin: 0 10px 0 0;
        }
      }
    }
    .gt-c {
      position: absolute;
      top: 75px;
      bottom: 56px;
      left: 20px;
      right: 20px;
      .gtc-t {
        display: flex;
        .gq-btn {
          height: 30px;
          line-height: 30px;
          padding: 0 10px;
          border: 1px solid #ddd;
          border-radius: 3px;
          margin: 0 5px 0 0;
          cursor: pointer;
          font-size: 14px;
          color: #666;
        }
      }
      .gtc-b {
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;
        bottom: 0;
        border:1px solid #555;
      }
    }
    .btns{
      position:absolute;
      bottom:0;
      height:50px;
      left:0;
      right:0;
      padding:0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items:center;
      .ac-btn{
        height:36px;
        line-height: 36px;
        padding:0 20px;
        margin:0 0 0 10px;
        border-radius: 4px;
        border: 1px solid #C6C6C6;
        font-size: 14px; 
        color: #ADBAAE;
        cursor:pointer;
        &:nth-child(2){
          background: #027AFF;
          border-radius: 4px;
          border:1px solid transparent;
          color: #FFFFFF;
        }
        &.disable{
          opacity: 0.5;
          cursor:no-drop;
        }
      }
    }
  }
  .task-box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
    .tbox-c{
      width: 500px;
      height: 320px;
      background: #fff;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .tbc-t{
        height:40px;
        display: flex;
        justify-content: flex-end;
        border-bottom:1px solid #ddd;
        .close-btn{
          padding:0 20px;
          font-size: 18px;
          line-height: 40px;
          cursor: pointer;

        }
      }
      .tbc-c{
        box-sizing: border-box;
        padding:0 20px;
        .tbc-line{
          display: flex;
          align-items: center;
          margin:10px 0 0;
          .tl-title{
            width:120px;
            text-align: right;
            font-size: 14px;
            color:#333;
          }
          .tl-content{
            width:220px;
            .el-select{
              width:100%;
            }
          }
        }
      }
      .tbc-b{
        position:absolute;
        bottom:0;
        width:100%;
        height:50px;
        border-top:1px solid #ddd;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .tbc-btn{
          padding:0 10px;
          border-radius: 3px;
          cursor:pointer;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color:#fff;
          &:nth-child(1){
            background: #ff9900;
          }
          &:nth-child(2){
            background: red;
          }
          &:nth-child(3){
            background: green;
          }
        }
      }
    }
  }
}
</style>