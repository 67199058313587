import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 导入实际进度
export const UpLoad=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Production/UpLoad`,
        data:datas
    })
}

// 获取项目计划列表
export const GetPlanProjectList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/GetPlanProjectList`,
        params:datas
    })
}
export const AddPlanProject=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Production/AddPlanProject`,
        data:datas
    })
}

// 获取项目计划列表
export const GetTreeData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/GetTreeData`,
        params:datas
    })
}

export const AddProjectTask=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Production/AddProjectTask`,
        data:datas
    })
}

// 删除任务
export const DeleteData=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Production/DeleteData`,
        data:datas
    })
}

// 项目是否启用
export const UpdatePlanProjectStart=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/UpdatePlanProjectStart`,
        params:datas
    })
}


// 删除计划进度
export const DeletePlanProject=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/DeletePlanProject`,
        params:datas
    })
}

// ↓  实际进度的接口
// 获取实际进度列表
export const GetPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/GetPageList`,
        params:datas
    })
}


// 获取计划进度下拉列表
export const GetPlanProjectDropDownList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/GetPlanProjectDropDownList`,
        params:datas
    })
}

// 实际进度编辑任务
export const Update_Task=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Production/Update_Task`,
        data:datas
    })
}


// 添加实际进度
export const AddProductionProject=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Production/AddProductionProject`,
        data:datas
    })
}

// 删除实际进度
export const DeletetProductionProject=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Production/DeletetProductionProject`,
        params:datas
    })
}

